import { createApp } from "vue";
import { VueReCaptcha } from "vue-recaptcha-v3";
import VeeValidate from "vee-validate";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import './assets/css/index.css'

createApp(App).use(store).use(router).use(VeeValidate).use(VueReCaptcha,{siteKey:'6Lc3gukjAAAAADBJptO7Vlugr8bQv2yVdYMeAxf2', loaderOptions: {
    useRecaptchaNet: true
  }}).mount("#app");
